import React from 'react'
import styled from 'styled-components/macro'

import Layout from '../components/layout'
import Terms from '../components/Terms'

const terms = () => (
  <Layout hideSignUpButton={true}>
    <Terms />
  </Layout>
)


export default terms
